import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./custom.css";

const CategorySearchBar = () => {
  const [query, setQuery] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const navigate = useNavigate();

  const titles = [
    "Search for a category...",
    "Search Nagpur's hotels...",
    "Search Nagpur's schools...",
    "Search Nagpur's colleges...",
    "Find Everything with NagpurDial...",
  ];

  useEffect(() => {
    let index = 0;
    const rotateTitle = () => {
      setPlaceholder(titles[index]);
      index = (index + 1) % titles.length;
    };

    rotateTitle(); // Set initial placeholder
    const intervalId = setInterval(rotateTitle, 3000); // Change the placeholder every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const handleSearch = () => {
    if (query.trim()) {
      navigate(`/results/${encodeURIComponent(query)}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission default behavior
      handleSearch(); // Trigger search
    }
  };

  return (
    <div>
      <form className="search-location-form">
        <input
          type="text"
          placeholder={placeholder} // Dynamic placeholder
          id="input-box"
          name="search"
          className="form-control"
          autoComplete="off"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown} // Listen for Enter key
          style={{ flex: 1 }} // Allow the input to take available space
        />
        <Button
          className="mx-2"
          variant="primary"
          onClick={handleSearch}
          style={{ whiteSpace: "nowrap" }} // Ensure button text doesn't wrap
        >
          Search
        </Button>
        </form>
    </div>
  );
};

export default CategorySearchBar;